import axios from "axios";
import {
  LocalStorage,
  getHeaders,
} from "../../utils/local_storage/local_storage";
import { toast } from "react-hot-toast";
import { Base64Utils } from "../../utils/base64/base64_utils";
import { Network } from "../../utils/axios/axios_utils";

const serverOnBoarding = process.env.REACT_APP_API_ONBOARDING;

export class IdentityService {
  ocrReader = async ({
    base64image,
    documentType,
    documentCountry,
  }: {
    base64image: string;
    documentType: string;
    documentCountry: string;
  }) => {
    const response = await Network.post("onboarding-processes/ocr-reader", {
      documentImage: new Base64Utils().clean(base64image),
      documentType: documentType,
      documentCountry: documentCountry,
    });

    //toast(JSON.stringify(response.data));

    return response.data;
  };

  barCode = async (base64image: string) => {
    const response = await Network.post("onboarding-processes/barcode-reader", {
      documentImage: new Base64Utils().clean(base64image),
    });

    return response.data;
  };

  markAsForRevision = async () => {
    const response = await Network.post("onboarding-processes/due-diligence");

    //toast(JSON.stringify(response.data));

    return response.data;
  };

  getCountriesWithOCR = async () => {
    const response = await Network.get(
      "onboarding-processes/ocr-reader-available-countries"
    );

    //toast(JSON.stringify(response.data));

    return response.data.payload;
  };
}
