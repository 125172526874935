import axios from "axios";
import {
  LocalStorage,
  getHeaders,
} from "../../utils/local_storage/local_storage";
import { argentinaCities } from "./argentina_cities";
import { colombiaCities } from "./colombia_ctiies";
import { ukCities } from "./uk_cities";
import { brasilCities } from "./brasil_cities";
import { mexicoCities } from "./mexico_cities";
import { usaCities } from "./usa_cities";
import { spainCities } from "./spain_cities";
import { Network } from "../../utils/axios/axios_utils";

export class AddressServiceClass {
  getStates = (countryAbbr: string) => {
    switch (countryAbbr) {
      case "ARG":
      case "ARGENTINA":
        return argentinaCities;
      case "CO":
      case "COLOMBIA":
        return colombiaCities;
      case "UK":
      case "Reino Unido":
        return ukCities;
      case "BR":
      case "BRAZIL":
      case "BRASIL":
        return brasilCities;
      case "MX":
      case "MEXICO":
        return mexicoCities;
      case "US":
      case "ESTADOS UNIDOS":
        return usaCities;

      case "ESPAÑA":
      case "ES":
        return spainCities;
      default:
        return colombiaCities;
    }
  };

  getCitiesOfState = (state: State, countryAbbr: string) => {
    const stateObj = this.getStates(countryAbbr).find(
      (s: any) => s.name === state.name
    );
    return stateObj
      ? stateObj.municipalities.map((e: any) => {
          return { name: e };
        })
      : [];
  };

  saveDirections = async (payload: any) => {
    const response = await Network.post("address", payload, {
      baseURL: process.env.REACT_APP_URL_USER,
    });

    return response.data;
  };

  validatePDF = async (payload: any) => {
    const response = await Network.post(
      "onboarding-processes/address-verification",
      payload
    );

    return response.data;
  };

  getDocument = async () => {
    const response = await Network.get("user-documents");

    return response.data;
  };
}

export interface State {
  name: string;
  alias: string[];
  municipalities: string[];
}
